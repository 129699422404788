import {useEffect, useState, useLayoutEffect, useCallback} from 'react'
import { useSelector, useDispatch } from "react-redux";
import { BiSearchAlt2, BiFilter } from "react-icons/bi";
import { HiUser } from "react-icons/hi";
import {getLedgers} from "../../store/slices/FriendsLedgerSlice"
import { Themes } from '../../components/Themes/Themes';
import { motion } from "framer-motion";
import { SharedLedgersCards } from '../../components/Cards/Cards';
import { useNavigate } from 'react-router-dom';
import { RxCross2 } from "react-icons/rx";
import { getUser } from "../../store/slices/auth";
import { TbReload } from "react-icons/tb";
import { getAllUsers } from "../../store/slices/users";
import { LoadingName } from '../../components/Spinners/Spinners';


const SharedLedgersDashboard = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [searchData, setSearchData] = useState("");
    const [sortData, setSortData] = useState("all");

    const userData = useSelector((state) =>{
        return state.userAuth
    })

    
    const allUsers = useSelector((state) =>{
        return state.allUsers
    })

    const ledgersData = useSelector((state) =>{
        return state.friendsLedgers
    })

    let ledgers = ledgersData?.data?.filter((item) => {
        return item.SUID === userData?.user?._id;
    });

    const animation = {
        initial: {opacity: 0, x : -100},
        animate: {opacity: 1, x: 0},
        exit: {opacity:0, x: 100}
    }

    const dataFetch = useCallback(() => {
        dispatch(getUser())
        dispatch(getAllUsers())
        dispatch(getLedgers(userData?.user?._id))
    }, [dispatch,userData?.user?._id, ledgersData]);
    
    useEffect(() => {
    }, [searchData, sortData]);
    
    useLayoutEffect(() => {
        if(userData?.user?.length === 0 ){
            dispatch(getUser())
            dispatch(getAllUsers())
        }
    }, [userData,dispatch]);
    
    useLayoutEffect(() => {
            dispatch(getLedgers(userData?.user?._id))
            // console.log("callled")
    }, [dispatch,userData?.user?._id]);


  return ( 
    <motion.div 
    variants={animation}
    initial="initial"
    animate="animate"
    exit="exit"
    transition={{duration: 0.2}}
    style={{ height: "100vh"}}
    // transition={{duration: 1}}
    >
            {/* main body of the Home */}
            <div className="mt-0  main_outer_con d-flex position-relative flex-column">

                    {
                        // ledgers?.data?.length === 0 ?  "" :
                        <div className=" search_sec  position-fixed px-3" >
                                    <div className=" position-relative search_inner ">
                                        <input type="text" className="form-control rounded-pill" 
                                        name="search"
                                        value={searchData} 
                                        onChange={ (e) => setSearchData(e.target.value) }
                                        placeholder="Search by Title, Name"
                                        style={{paddingLeft:"37px", paddingRight:"30px"}}
                                        id="search_btn"
                                        />
                                        <span className="position-absolute top-0 start-0 ms-2 fs-4 " style={{color: "grey"}} >
                                            <BiSearchAlt2/> 
                                        </span>
                                        <span 
                                        className={`position-absolute d-flex mt-2 top-0 end-0 me-2 fs-4 ${searchData.length === 0? 'd-none' : ""} `} 
                                        style={{color: "grey", cursor:"pointer"}} onClick={()=> setSearchData("")}
                                         >
                                            <RxCross2/> 
                                        </span>
                                    </div>

                                    <div className="btn-group" style={{width: "30px"}}>
                                        <button className="btn bg-light rounded ms-1 fs-4 d-flex align-items-center border-0 btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <BiFilter/>
                                        </button>
                                        <ul className="dropdown-menu px-3">
                                        <li className={`dropdown-item ${sortData === "all"? 'text-info' : ""} `} onClick={() => setSortData("all")} > All</li>
                                            <li className={`dropdown-item ${sortData === "active"? 'text-info' : ""} `} onClick={() => setSortData("active")} > Active </li>
                                            <li className={`dropdown-item ${sortData === "deactive"? 'text-info' : ""} `} onClick={() => setSortData("deactive")}  > Deactive </li>

                                        </ul>
                                    </div>
                                    <button  className="btn bg-info ms-3 bg-light rounded fs-3 d-flex align-items-center border-0 btn-sm"
                                    onClick={dataFetch}
                                    style={{width: "35px"}}> <TbReload/> </button>
                                    <div onClick={()=> navigate(-1)} className="btn bg-info ms-1 bg-light rounded fs-3 d-flex align-items-center border-0 btn-sm" 
                                    style={{width: "35px"}}> <HiUser/> </div>
                        </div>
                    }


                    <div className=" d-flex  cards_display flex-wrap ps-lg-4 " style={{paddingTop: "55px", paddingBottom:"60px"}} >
                    {/* <LoadingCards />  */}
                        {
                            ledgersData?.loading ? 
                            <LoadingName />
                            //   <div className=" d-flex align-items-center justify-content-center" style={{height:"90vh", width:"100vw"}} >
                            //       <p className="text-center text-secondary">Loading...</p> 
                            //   </div> 
                               :
                            ledgers?.length === 0 ?  
                                <div className=" w-100 d-flex justify-content-center text-secondary align-items-center" 
                                style={{height:"70vh"}}
                                > 
                                No shared ledgers to show. </div> 
                            :
                            ledgers?.filter((ele)=>{  

                                if(searchData?.length === 0){
                                    return ele
                                }else{ 

                                    return ele?.title?.toLowerCase()?.includes(searchData?.toLowerCase()) || ele?.UName?.toLowerCase().includes(searchData?.toLowerCase())
                                }

                            })
                            ?.filter((ele)=>{
                                if(sortData === "all"){
                                    return ele
                                }else if(sortData === "active"){
                                    return ele.status === true
                                }else if(sortData === "deactive"){
                                    return ele.status === false
                                }
                            })
                            ?.map(ele =>{
                                
                                let res = Themes.find((item) => {
                                    return item.ID === ele.theme;
                                });

                                return(
                                    <div key={ele._id}>
                                    <motion.div
                                     animate={{ x: 0 }}
                                    >
                                        <SharedLedgersCards theme={res} id={ele._id} url={`/friends-ledger-details/${ele?._id}`}
                                        data={ele} authData={userData?.user} usersData={allUsers} />    
                                    </motion.div>
                                    </div>
                                )
                            })
                        }
    
                    </div>
                   
                </div>
    </motion.div>
  )
}

export default SharedLedgersDashboard