import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getLedgers = createAsyncThunk("getLedgers", async (id) =>{
    try {
        // console.log("ledger called", id)
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/friends-ledgers-data/${id}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          },
          credentials: "include",
        });

        if(res.status === 401){
          window.location.reload(false);
        }
  
        if (!res.status === 200) {
        //   throw new Error(res.error);
            return res.error
        }
        
        return await res.json();
        // console.log(data)
        // console.log("from the checking component==>",data);
  
  
  
      } catch (err) {
        console.log("error => ",err);
        return err
      //   history.push('/');
      }
})

const friendsLedgerSlice = createSlice({
    name: "friendsLedger",
    initialState: {
        loading: false,
        data:[],
        error:''
    },
    // reducers: {
    //     createLedger(state, action){
    //         state.push(action.payload);
    //     },
    //     removeLedger(state, action){
    //         state.splice(action.payload, 1)
    //     },
    //     toggleStatus(state, action){
    //         state[action.payload[0]].status = action.payload[1]
    //     },
    //     editLedgerData(state, action){
    //         const { title, theme, desc} = action.payload[1]
    //         state[action.payload[0]].title = title
    //         state[action.payload[0]].desc = desc
    //         state[action.payload[0]].theme = theme
    //     },
    //     addTransaction(state, action){
    //         const {id,newAmount, newBalance,status, transactionData} = action.payload
    //         state[id].amount = newAmount
    //         state[id].balanceAmount = newBalance
    //         state[id].status = status
    //         state[id].transactionData.push(transactionData)
    //      },

    // },
    extraReducers: (builder) => {
        builder
          .addCase(getLedgers.pending, (state) => {
            state.loading = true;
          })
          .addCase(getLedgers.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
          })
          .addCase(getLedgers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
          });
      },
})

// console.log(friendsLedgerSlice.actions);

export default  friendsLedgerSlice.reducer

// export const { } = friendsLedgerSlice.actions