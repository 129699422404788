import {useState, useEffect, useLayoutEffect, Suspense, useCallback} from "react"
import { lazily } from 'react-lazily';
import Spinners,{LoadingName} from "../../components/Spinners/Spinners";
import { IoMdAdd } from "react-icons/io";
import "./CSS/FriendsLedgerDashboard.css"
import {Themes} from "../../components/Themes/Themes"
import { GiCheckMark } from "react-icons/gi";
// import { AiOutlineSearch } from "react-icons/ai";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MdErrorOutline } from "react-icons/md";
import { Tooltip } from 'react-tooltip'
import { toast } from 'sonner';
import { useSelector, useDispatch } from "react-redux";
import { BiSearchAlt2, BiFilter } from "react-icons/bi";
import { HiUsers } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import {getLedgers} from "../../store/slices/FriendsLedgerSlice"
import { getUser } from "../../store/slices/auth";
import { getAllUsers } from "../../store/slices/users";
import { Link } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { TbReload } from "react-icons/tb";

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { motion } from "framer-motion";

const { LedgersCards } = lazily(() => import("../../components/Cards/Cards"))
const  {DatePicker} = lazily( () => import('@mui/x-date-pickers/DatePicker')) 

const FriendsLedgerDashboard = () => {

    const [themeSelected, setThemeSelected] = useState("Theme_1");
    const [members, setMembers] = useState(false);
    const [shareOpt, setShareOpt] = useState(false);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [radioValue, setRadioValue] = useState("Lend");
    const [transactionMode, setTransactionMode] = useState('online');
    const [date, setDate] = useState(null);
    const [dateError, setDateError] = useState(false);
    const [txtDescStatus, setTxtDescStatus] = useState(false);
    const [searchData, setSearchData] = useState("");
    const [sortData, setSortData] = useState("");
    const [numberCheck, setNumberCheck] = useState(true);
    const [submitBtn, setSubmitBtn] = useState(false);
    const [ledgerAPIData, setLedgerAPIData] = useState(null);

    const [editLedgerOpt, setEditLedgerOpt] = useState({
        id:'',
        status: false
    });

    const dispatch = useDispatch();

    const userData = useSelector((state) =>{
        return state.userAuth 
    })

    const ledgersData = useSelector((state) =>{
        return state.friendsLedgers
    })

    
    let ledgers = ledgersData?.data?.filter((item) => {
        return item.UID === userData?.user?._id;
    });
    

    const allUsers = useSelector((state) =>{
        return state.allUsers
    })


    const handleClose = () =>{
        
        setShow(false)
        setEditLedgerOpt({
            id: '',
            status: false
        })

        setLedgerData({
            title: '',
            desc: '',
            S_pro_pic:'',
            SUID:'',
            person_name: '',
            share: false,
            reg_num: '',
            theme: '',
            status: true,
            amount:'',
            transaction: '',
            mode: ''
        })

        
        setLedgerDataError({
            title: false,
            desc: false,
            person_name: false,
            reg_num: false,
            amount:false,    
            transactionDate: false            
        })

        setMembers(false)
        setThemeSelected("Theme_1")
        setShareOpt(false)
        setRadioValue('Lend')
        setTransactionMode('online')
        setDate(dayjs(new Date().toLocaleString()))
        setDateError(false)
        setTxtDescStatus(false)
        setSubmitBtn(false)
    
    };

    const handleShow = () =>{
        
        setShow(true)
        setDate(dayjs(new Date()))
    };


    let [ledgerData, setLedgerData] = useState({
        title: '',
        UID:'',
        S_pro_pic:'',
        SUID:'',
        desc: '',
        person_name: '',
        share: false,
        reg_num: '',
        theme: '',
        status: true,
        amount: '',
        balanceAmount: '',
        transaction: "",
        mode:'',
        transactionDate: '',
        desc_trans:''
    });
    const [ledgerDataError, setLedgerDataError] = useState({
        title: false,
        desc: false,
        person_name: false,
        reg_num: false,
        amount: false,
        transactionDate: false,
        desc_trans:false
    });

    const inputEvent = (e) => {
        let { name, value } = e.target;
        setLedgerData({ ...ledgerData, [name]: value })
    
    }

    const handleChange = event => {
        // setShareOpt(current => !current);
        setShareOpt(event.target.checked);
        ledgerData.share = !shareOpt
        setSubmitBtn(!shareOpt)
        if(shareOpt){
            ledgerDataError.reg_num = false
            ledgerData.reg_num = ""
            hideValidate(document.getElementById("reg_num_input"))
            setMembers(false)
        }
      };
    
    const hideValidate = (ele) =>{
        ele.style.border = "1px solid rgb(206, 206, 206)";
      }
    const showValidate = (ele) =>{
        ele.style.border = "1px solid red";
      }
      

const checkUser = async (e, value, name) =>{
    e.preventDefault();
    let reg_num_input = document.getElementById("reg_num_input");

    if(String(value)?.trim()?.length === 10 && Number(value) !== userData?.user?.number){
        try {
                
            const res = await fetch(`${process.env.REACT_APP_BASE_URL}/get-user-number/${Number(value)}`, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`
              },
              credentials: "include",
            });

            const data =  await res.json();

            if (res.status === 404) {
            //   throw new Error(res.error);
                toast.error("Oops user not found!!..",{duration: 1500})
                setLedgerDataError({...ledgerDataError, [name] : true})
                showValidate(reg_num_input)
            }else if(res.status === 200){
                setMembers(data) 
                hideValidate(reg_num_input);
                setLedgerDataError({...ledgerDataError, [name] : false})
                setNumberCheck(true)
                setSubmitBtn(false)
                // console.log("from the checking component==>",data);
                // dispatch(getUser())
                // toast.success(data.message,{duration: 1500})
                
            }
      
          } catch (err) {
            console.log(err);
            toast.error("Oops something went wrong!!..",{duration: 1500})
          }

       
        // if(res.length === 0 ){
        //     toast.error("oops User not found!!..")
        //     setLedgerDataError({...ledgerDataError, [name] : true})
        //     // reg_num_input.focus()
        //     showValidate(reg_num_input)
        //     // console.log("number found => ",res)
        // } else{
        //     // console.log("number found => ",res)
        //     hideValidate(reg_num_input);
        //     setLedgerDataError({...ledgerDataError, [name] : false})
        //     setNumberCheck(true)
        //     setSubmitBtn(false)
        // }
        
    }
}

const HandelOnBlur = (name, value) =>{
    // alert(`On Blur value=> ${value}, id=> ${id}`)
    let title_input = document.getElementById("title_input");
    let desc_input = document.getElementById("desc_input");
    let desc_input_trans = document.getElementById("desc_input_trans");
    let person_name_input = document.getElementById("person_name_input");
    let reg_num_input = document.getElementById("reg_num_input");
    let initial_amt = document.getElementById("initial_amt");


    
    // validate First Name
    if(name === "title"){
      if (value?.trim() === "") {
        showValidate(title_input)
        setLedgerDataError({...ledgerDataError, [name] : true})
    }else if(value?.trim().length > 25){
          showValidate(title_input)
          setLedgerDataError({...ledgerDataError, [name] : true})
      }else {
        setLedgerDataError({...ledgerDataError, [name] : false})
        hideValidate(title_input)
      }

    }

    // validate description
    if(name === "desc"){
       if(value?.trim().length > 130){
        showValidate(desc_input)
        setLedgerDataError({...ledgerDataError, [name] : true})
    }else {
        setLedgerDataError({...ledgerDataError, [name] : false})
        hideValidate(desc_input)
      }

    }

    // validate description transaction
    if(name === "desc_trans"){
       if(value?.trim().length > 60){
        showValidate(desc_input_trans)
        setLedgerDataError({...ledgerDataError, [name] : true})
    }else {
        setLedgerDataError({...ledgerDataError, [name] : false})
        hideValidate(desc_input_trans)
      }

    }

    //validate from user
    if(name === "person_name"){
       if (value?.trim() === "") {
        showValidate(person_name_input)
        setLedgerDataError({...ledgerDataError, [name] : true})
      } else {
        setLedgerDataError({...ledgerDataError, [name] : false})
        hideValidate(person_name_input);
      }

    }

    //validate register numbers
    if(name === "reg_num"){
          if (String(value)?.trim() === "") {
            showValidate(reg_num_input)
            setMembers(false)
            setLedgerDataError({...ledgerDataError, [name] : true})
        } else if (String(value)?.trim()?.length !== 10) {
            showValidate(reg_num_input)
            setMembers(false)
            setLedgerDataError({...ledgerDataError, [name] : true})
        }else {
            if(String(value)?.trim()?.length === 10){

                if(Number(value) === userData?.user?.number ){
                    showValidate(reg_num_input)
                    setLedgerDataError({...ledgerDataError, [name] : true})
                    // console.log("number found => ",res)
                } else{
                    // console.log("number found => ",res)
                    hideValidate(reg_num_input);
                    setLedgerDataError({...ledgerDataError, [name] : false})
                }
                
            }
          }

    }

    //validate Amount numbers
    if(name === "amount"){
          if (value?.trim() === "") {
            showValidate(initial_amt)
            setLedgerDataError({...ledgerDataError, [name] : true})
          } else if (Number(value?.trim()) <= 0) {
            showValidate(initial_amt)
            setLedgerDataError({...ledgerDataError, [name] : true})
          }else if (String(value?.trim()).includes('.') || !/^\d+$/.test(value)) {
            showValidate(initial_amt)
            setLedgerDataError({...ledgerDataError, [name] : true})
          } else {
            hideValidate(initial_amt);
            setLedgerDataError({...ledgerDataError, [name] : false})
          }

    }




}

const CreateLedgerData = async (e) =>{
    e.preventDefault()
    setLoading(true)
    
    let {title ,desc, person_name, share, reg_num, amount} = ledgerData
    let dt
    date !== null ? dt = date.$D+"/"+(date.$M+1)+"/"+date.$y : dt = null
    
    title = title.replace(/\s+/g,' ').trim() 
    desc = desc.replace(/\s+/g,' ').trim()
    person_name = person_name.replace(/\s+/g,' ').trim()
    reg_num = reg_num.trim()
    amount = amount.replace(/\s+/g,' ').trim()
    ledgerData.theme = themeSelected;
    ledgerData.transaction = radioValue;
    ledgerData.mode = transactionMode;
    ledgerData.transactionDate = dt;
    ledgerData.UID = userData?.user?._id;
    ledgerData.balanceAmount = amount;
    
    if(members){
        ledgerData.S_pro_pic = members?.Profile_pic
        ledgerData.SUID = members?._id
    }
    // ledgerData.S_pro_pic = 
    // console.log(dt)
    
    if(title === "" && person_name === ""&& amount=== "" && dt === null){
        document.getElementById("title_input").focus();
        toast.error("please fill the details.",{duration: 1500})
    }else if(share && reg_num === ""){
        document.getElementById("reg_num_input").focus();
        HandelOnBlur("reg_num", "")
    }else if(title === ""|| person_name === "" || amount === "" || dt === null){
        if(dt === null ){
            ledgerDataError.transactionDate = true;
            setDateError(true) 
        }
        // console.log(ledgerData.transactionDate)
        HandelOnBlur("title", title)
        HandelOnBlur("desc", desc)
        HandelOnBlur("person_name", person_name)
        HandelOnBlur("amount", amount)
    }
    else{

        HandelOnBlur("title", title)
        HandelOnBlur("desc", desc)
        HandelOnBlur("person_name", person_name)
        HandelOnBlur("amount", amount)
        if(shareOpt){
            HandelOnBlur("reg_num", ledgerData.reg_num)
        } 

        let validated = Object.keys(ledgerDataError).every(function(k){ return ledgerDataError[k] === false });
        // console.log("validated =>", validated)

        if(validated){
            // console.log(ledgerData)
            if(editLedgerOpt.status){
                // let data={
                //     title: title,
                //     desc: desc,
                //     theme: ledgerData.theme,
                //     reg_num: ledgerData.reg_num,
                //     SUID: ledgerData.SUID,
                // }
                // dispatch(editLedgerData([editLedgerOpt.id,data])) 
                // console.log(data)
                // toast.success("Edited Successfully!!..")  
                handleClose()
            } else{
                let transactionData={
                    from: radioValue === "Lend"? userData?.user?.firstName: person_name,
                    to: radioValue === "Borrow"? userData?.user?.firstName: person_name,
                    amount: amount,
                    balance: amount,
                    desc: ledgerData.desc_trans,
                    date:dt,
                    mode: transactionMode,
                    new_amount: amount
                }
                ledgerData={
                    ...ledgerData,transactionData:[transactionData]
                }

                // console.log(ledgerData)

                try {
                
                    const res = await fetch(`${process.env.REACT_APP_BASE_URL}/add-friends-ledger`, {
                      method: "POST",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                      },
                      credentials: "include",
                      body: JSON.stringify({
                        UID: ledgerData.UID , UName: userData?.user?.firstName , title,desc,person_name,share,status: ledgerData.status,reg_num, 
                        SUID: ledgerData.SUID  ,amount,
                        balanceAmount: ledgerData.balanceAmount ,transactionType : ledgerData.transaction,mode: ledgerData.mode,
                        transactionDate:dt, transactionData, theme:ledgerData.theme
                      })
                    });
                    // console.log(res.status)
                    // console.log(res.body) 
                    if (res.status === 400) {
                    //   throw new Error(res.error);
                        toast.error("Oops something went wrong",{duration: 1500})
                    }else if(res.status === 422) {
                        toast.error("Please fill all the details",{duration: 1500})
                    }else if(res.status === 500){
                        toast.error("Oop's something went wrong try after some time",{duration: 1500})
                    }else{
                        const data =  await res.json();
                        // console.log("from the checking component==>",data);
                        // dispatch(getUser())
                        toast.success(data.message,{duration: 1500})
                        dispatch(getUser())
                        dispatch(getLedgers(userData?.user?._id))
                    }
              
                  } catch (err) {
                    console.log(err);
                    toast.error("Oops something went wrong!!..",{duration: 1500})
                  }

                handleClose()
            }
          
        }else{
            HandelOnBlur("title", title)
            HandelOnBlur("desc", desc)
            HandelOnBlur("person_name", person_name)
            HandelOnBlur("amount", amount)
            // toast.error("please fill all the credentials")
        }
    }

    setLoading(false)

}

const EditLedgerData = async (e) =>{
    e.preventDefault()
    setLoading(true)
    // console.log("edit ledger called")

    let {title , share, reg_num, person_name} = ledgerData
    if(members && ledgerData.SUID === ""){
        ledgerData.S_pro_pic = members?.Profile_pic
        ledgerData.SUID = members?._id
    }

    // console.log(ledgerData)
    if(title === "" && person_name === ""){
        document.getElementById("title_input").focus();
        toast.error("please fill the details.",{duration: 1500})
    }else if(share && reg_num === ""){
        document.getElementById("reg_num_input").focus();
        HandelOnBlur("reg_num", "")
    }else{ 
        if(shareOpt){
            HandelOnBlur("reg_num", ledgerData.reg_num)
        } 
        let validated = Object.keys(ledgerDataError).every(function(k){ return ledgerDataError[k] === false });
        // console.log(ledgerDataError)
        // console.log(validated) 
        if(validated){

            let data={
                title: ledgerData.title,
                desc: ledgerData.desc,
                theme: themeSelected,
                reg_num: ledgerData.reg_num,
                SUID: ledgerData.SUID,
                S_pro_pic: ledgerData.S_pro_pic,
                share: ledgerData.share,
                person_name: ledgerData.person_name
            }
            // dispatch(editLedgerData([editLedgerOpt.id,data])) 
            try {
                    
                const res = await fetch(`${process.env.REACT_APP_BASE_URL}/edit-friends-ledger/${editLedgerOpt.id}`, {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                  },
                  credentials: "include",
                  body: JSON.stringify({
                    data
                  })
                });
                // console.log(res.status)
                // console.log(res.body)
                const resData =  await res.json();

                if (res.status === 400) {
                //   throw new Error(res.error);
                    toast.error("Oops something went wrong!..",{duration: 1500})
                }else if(res.status === 200){
                    // console.log("from the checking component==>",data);
                    // dispatch(getUser())
                    toast.success(resData?.message, {duration: 1500})
                    dispatch(getUser())
                    dispatch(getLedgers(userData?.user?._id))
                }else{
                    toast.error("Oops something went wrong!!..", {duration: 1500})
                }
          
              } catch (err) {
                // console.log(err);
                toast.error("Oops something went wrong!!..",{duration: 1500})
              }
              handleClose()
        }
        
        // console.log(data)
        // toast.success("Edited Successfully!!..")  
    }

    setLoading(false)
}

const radios = [
    { name: 'Lend', value: 'Lend' },
    { name: 'Borrow', value: 'Borrow' },
  ];

  
const EditLedger = (id) =>{ 

    let res = ledgers?.filter((item) => {
        return item._id === id ;
    });

    // console.log("res data=>",res[0])
      let {UID,title,desc,person_name,share,status,reg_num, S_pro_pic,SUID,amount,balanceAmount,transactionType,mode,transactionDate,theme} = res[0]
      setLedgerAPIData(res[0])

    setLedgerData({
        title: title,
        UID: UID,
        desc: desc,
        SUID: SUID,
        S_pro_pic: S_pro_pic,
        person_name: person_name,
        share: share,
        reg_num: reg_num === null? '':reg_num,
        theme: theme,
        status: status,
        amount: amount,
        balanceAmount: balanceAmount,
        transaction: transactionType,
        mode: mode,
        transactionDate: transactionDate
    })

    status? setTxtDescStatus(false) : setTxtDescStatus(true)

    setThemeSelected(theme)
    setEditLedgerOpt({
        id: id,
        status: true
    })

    let memberData = allUsers?.users?.filter((item) => {
            return item._id === SUID ;
        });
        // console.log(memberData[0])
    setMembers(memberData[0]) 

    handleShow()
    setRadioValue(transactionType)
    setTransactionMode(mode)
    setDate(dayjs(transactionDate?.split('/').reverse().join('/')))
    setShareOpt(share)
    setNumberCheck(true)
    setSubmitBtn(true)
    
    }

    // change the status of the submit button on edit  
    useEffect(() => {
        if(editLedgerOpt.status){
            if(ledgerData.title !== ledgerAPIData?.title || ledgerData.desc !== ledgerAPIData?.desc || ledgerData.person_name !== ledgerAPIData?.person_name || ledgerData.theme !== themeSelected){
                setSubmitBtn(false)       
            }else{
                setSubmitBtn(true)       
            }   
        }
    },[themeSelected, ledgerData, editLedgerOpt.status, ledgerAPIData]);

    const dataFetch = useCallback(() => {
        dispatch(getUser())
        dispatch(getAllUsers())
        dispatch(getLedgers(userData?.user?._id))
    }, [dispatch,userData?.user?._id, ledgersData]);
    
    // useCallback(() => {
    //     console.log("rerender search")
    // }, [searchData, sortData]);  

    useEffect(() => {
        if(String(ledgerData?.reg_num)?.trim()?.length === 10 ){
            setNumberCheck(false)
        }else{
            setNumberCheck(true)
        }
    }, [ledgerData]);

    useEffect(() => {
    }, [ledgerData]);
    
    useLayoutEffect(() => {
    if(userData?.user?.length === 0 ){
        dispatch(getUser())
        dispatch(getAllUsers())
    }
    }, [userData,dispatch]);

    useLayoutEffect(() => {
        dispatch(getLedgers(userData?.user?._id))
        if(localStorage.getItem("FriendsSort")){
            setSortData(localStorage.getItem("FriendsSort"));
        }else{
            setSortData('active')
            localStorage.setItem("FriendsSort","active")
        }
    }, [dispatch,userData?.user?._id]);

    const sortSearchData = (value) =>{
        setSortData(value)
        localStorage.setItem("FriendsSort",value)
    }

  return (
    <div>
            {/* add button */}
                <div  className={`add_btn test_btn z-3 position-fixed bottom-0 end-0 mb-4 me-4  mb-lg-4 me-lg-4 bg-warning rounded-circle`}
                onClick={handleShow} >
                    <IoMdAdd />
                </div>

            {/* <div className="  ">
                <div className="col-lg-6 px-3 bg-info d-flex justify-content-end ">
                        search
                </div>
            </div> */}


                {/* react modal friends ledger Information */}
               
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        centered
                        keyboard={false}
                        scrollable
                    >
                        <Modal.Header closeButton className="border-bottom-0 py-1" >
                        <Modal.Title>Ledger Information</Modal.Title>
                        </Modal.Header>
                        <Modal.Body> 
                        <form>
                                <div className="mb-3">
                                    <label htmlFor="recipient-name" className="col-form-label">Title </label>
                                    <div className=" position-relative">
                                        <input type="text" className="form-control" 
                                        name="title"
                                        onBlur={() => HandelOnBlur("title", ledgerData.title)}
                                        value={ledgerData.title} 
                                        onChange={inputEvent}
                                        disabled={txtDescStatus}
                                        maxLength="25"
                                        id="title_input"
                                        />
                                        <span className={` position-absolute top-0 end-0 me-2 text-danger ${!ledgerDataError.title?"d-none":"" } fs-5`}
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content="Please enter the title in 25 characters"
                                            data-tooltip-variant="error"
                                        > <MdErrorOutline/> </span>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="message-text" className="col-form-label">Description </label>
                                    <div className=" position-relative">
                                        <textarea className="form-control" style={{resize:"none"}} 
                                        name="desc"
                                        onBlur={() => HandelOnBlur("desc", ledgerData.desc)}
                                        value={ledgerData.desc} 
                                        onChange={inputEvent}
                                        disabled={txtDescStatus }
                                        maxLength="130"
                                        id="desc_input"></textarea>
                                        <span className={`position-absolute top-0 end-0 me-2 text-danger ${!ledgerDataError.desc?"d-none":"" } fs-5`}
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content="Please write the description in 130 characters."
                                            data-tooltip-variant="error"
                                        > <MdErrorOutline/> </span>
                                    </div>
                                </div>
                                {
                                    editLedgerOpt.status ? "" :

                                    <div className={`d-flex align-items-center justify-content-between`} >
                                        <ButtonGroup className="ms-auto " style={{zIndex:20}} >
                                            {radios.map((radio, idx) => (
                                            <ToggleButton
                                                className="py-1 px-3 "
                                                key={radio.value}
                                                style={{zIndex:20}}
                                                id={`radio-${idx}`}
                                                type="radio"
                                                variant={idx % 2 ?  'outline-danger':'outline-success' }
                                                name="radio"
                                                value={radio.value}
                                                checked={radioValue === radio.value}
                                                disabled={editLedgerOpt.status}
                                                onChange={(e) => setRadioValue(e.currentTarget.value)}
                                            >
                                                {radio.name}
                                            </ToggleButton>
                                            ))}
                                        </ButtonGroup>
                                    </div>
                                }


                                <div className="" >
                                    <div className="mb-3 "  >
                                        <label htmlFor="recipient-name" className="col-form-label">Person name
                                        <span className={` me-2 position-relative text-primary fs-5 `}
                                            style={{zIndex:"2000"}}
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-html="Please enter person name with whom <br/> you are doing transaction."
                                            data-tooltip-variant="info"
                                        > <MdErrorOutline/> </span>
                                         </label>
                                        <div className=" position-relative">
                                            <input type="text" className="form-control" 
                                            name="person_name"
                                            onBlur={() => HandelOnBlur("person_name", ledgerData.person_name)}
                                            value={ledgerData.person_name} 
                                            onChange={inputEvent}
                                            disabled={txtDescStatus}
                                            id="person_name_input"/>
                                            <span className={`position-absolute top-0 end-0 me-2 text-danger ${!ledgerDataError.person_name?"d-none":"" } fs-5`}
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content="Please enter person name."
                                            data-tooltip-variant="error"
                                        > <MdErrorOutline/> </span>
                                        </div>
                                    </div>

                                </div>
                                {
                                    editLedgerOpt.status ? "" :
                                    <>
                                        <div className={`input-group position-relative mb-3 d-flex align-items-center`}>
                                            <label className="form-label" style={{width: "30%"}} >Amount </label>
                                            <input type="number" className="form-control rounded"
                                            onWheel={(e) => e.target.blur()}
                                            placeholder="eg:- 500"
                                            name="amount"
                                            onBlur={() => HandelOnBlur("amount", ledgerData.amount)}
                                            value={ledgerData.amount} 
                                            onChange={inputEvent}
                                            id="initial_amt"
                                            disabled={editLedgerOpt.status}
                                            />
                                            <span className="input-group-text">.00</span>
                                            <span className={`position-absolute top-0 end-0 me-5 text-danger ${!ledgerDataError.amount?"d-none":"" } fs-5`}
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Please enter amount."
                                                    data-tooltip-variant="error"
                                                > <MdErrorOutline/> </span>
                                        </div>

                                        <div className=' amt_input input-group mb-3 fs-6 d-flex align-items-center' >
                                            <label  className="form-label  mb-0 ">Mode  </label>
                                            <select className="form-select rounded"
                                            value={transactionMode}
                                            disabled={editLedgerOpt.status}
                                            onChange={(e)=> setTransactionMode(e.target.value) }
                                            aria-label="Default select example">
                                                <option value="online" >Online</option>
                                                <option value="cash">Cash</option>
                                            </select>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="message-text" className="col-form-label">Transaction Description </label>
                                            <div className=" position-relative">
                                                <textarea className="form-control" style={{resize:"none"}} 
                                                name="desc_trans"
                                                onBlur={() => HandelOnBlur("desc_trans", ledgerData.desc_trans)}
                                                value={ledgerData.desc_trans} 
                                                onChange={inputEvent}
                                                disabled={txtDescStatus}
                                                maxLength="60"
                                                id="desc_input_trans"></textarea>
                                                <span className={`position-absolute top-0 end-0 me-2 text-danger ${!ledgerDataError.desc_trans?"d-none":"" } fs-5`}
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-content="Please write the description in 130 characters."
                                                    data-tooltip-variant="error"
                                                > <MdErrorOutline/> </span>
                                            </div>
                                        </div>
                                    </>
                                }

                                <Suspense fallback={<Spinner  animation="border" variant="primary" />} >
                                    <div className="mb-3">
                                    <label htmlFor="" className="">Date of Transaction </label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                        className="col-lg-12 "
                                        onChange={(newValue) => {
                                            setDate(newValue)
                                            setDateError(false)
                                            ledgerDataError.transactionDate = false
                                        }}          
                                        disabled={editLedgerOpt.status}
                                        value={date} 
                                        defaultValue={date}
                                        format="DD-MM-YYYY"
                                        disableFuture
                                        slotProps={{ textField: { size: 'small' } }}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderColor: `${dateError? 'red':'grey'}`,
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'rgb(206, 206, 206)',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'rgb(206, 206, 206)',
                                            },
                                            },
                                        }}
                                        />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                    </div>
                                </Suspense>

                                <div className="mb-3 mt-3" > 
                                    <div className="form-check form-switch d-flex justify-content-end">
                                        <input className="form-check-input px-2" 
                                        
                                        disabled={txtDescStatus || members }  checked={shareOpt}  value={shareOpt}
                                         name="shareOpt" onChange={handleChange}  type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
                                        <label className="form-check-label px-2"htmlFor="flexSwitchCheckDefault">Share </label>
                                    </div>
                                </div>
                                <div className={` ${shareOpt? "d-block": "d-none"}`}>
                                    <label htmlFor="recipient-name" className="col-form-label">Share with 
                                    <span className={` me-2 position-relative text-primary fs-5 `}
                                            style={{zIndex:"2000"}}
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-html="Please enter person number with whom <br/> you are doing transaction."
                                            data-tooltip-variant="info"
                                        > <MdErrorOutline/> </span>
                                    </label>
                                    <div className="d-flex justify-content-between reg_num_con">
                                        
                                        <div className=" position-relative" >
                                            <input type="number"className="form-control"
                                            onWheel={(e) => e.target.blur()}
                                            placeholder="Enter the Registered Number "
                                            name="reg_num"
                                            onBlur={() => HandelOnBlur("reg_num", ledgerData.reg_num)}
                                            value={ledgerData.reg_num} 
                                            // disabled={ledgerData.SUID === ""? false: true}
                                            disabled={members}
                                            onChange={inputEvent}
                                            id="reg_num_input"/>
                                            <span className={`position-absolute top-0 end-0 me-2 text-danger ${!ledgerDataError.reg_num?"d-none":"" } fs-5`}
                                                data-tooltip-id="my-tooltip"
                                                data-tooltip-content="Please enter the registered phone number."
                                                data-tooltip-variant="error"
                                            > <MdErrorOutline/> </span>
                                        </div>
                                        <button className="btn fs-6 btn-primary" disabled={numberCheck || members } onClick={(e) => checkUser(e,ledgerData.reg_num,"reg_num")}  >
                                            Add
                                        </button>
                                    </div>

                                    {/* members */}
                                    {
                                        members ? 
                                    <div className="d-flex">
                                        <div className="position-relative me-2 mt-2 " >
                                            <img src={`/images/profile_pics/${members?.Profile_pic}`} alt="" width="40px" height="40px" 
                                            className="rounded-circle mt-2 " />
                                            {
                                                !txtDescStatus ?
                                                <span className="position-absolute text-light bg-danger top-0 rounded-circle d-flex align-items-center  justify-content-center"
                                                style={{width:"20px", height:"20px", left:"25px"  ,cursor: "pointer"}}
                                                onClick={()=> {
                                                    if(window.confirm(`Do you want to remove ${members.firstName}`)){
                                                        ledgerDataError.reg_num = false
                                                        ledgerData.reg_num = ""
                                                        hideValidate(document.getElementById("reg_num_input"))
                                                        setMembers(false)
                                                        setShareOpt(false)
                                                        setSubmitBtn(false)
                                                        ledgerData.share = false
                                                        ledgerData.SUID = ""
                                                    }
                                                } }
                                                > X </span> 
                                                :""
                                            }
                                            <p className="p-0" >{members.firstName}</p>
                                        </div>
                                    </div>
                                            : ""
                                    } 
                                            
                                </div>

                                <div className="  mb-3 ">
                                    <label htmlFor="recipient-name" className="col-form-label">Theme </label>
                                    <div className="d-flex flex-wrap " >
                                        {
                                            Themes.map((ele) => {
                                                const {ID,bg,txt_color} = ele
                                                return(
                                                    <div key={ID}> 
                                                        <div   className={` border theme_styles`} 
                                                        style={{backgroundColor: bg, color: txt_color}}
                                                        onClick={()=> setThemeSelected(ID)} > {
                                                            themeSelected === ID ? <GiCheckMark/>: ""
                                                        }  </div> 
                                                    </div>
                                                )
                                            })
                                        }
                                        
                                        
                                    </div>
                                </div>
                                </form>
                        </Modal.Body>
                        <Modal.Footer  className="border-top-0 py-1" >
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" disabled={txtDescStatus || submitBtn}
                        onClick={(e) => editLedgerOpt.status? EditLedgerData(e) : CreateLedgerData(e)}  > { editLedgerOpt.status? "Save" :"Create" }  </Button>
                        </Modal.Footer>
                        <Tooltip id="my-tooltip" className="tooltip_style" />
                        {
                        loading?<Spinners />:""
                        }
                    </Modal>


                {/* main body of the Home */}
                <div className="mt-0  main_outer_con d-flex position-relative flex-column">

                    {
                        // ledgers?.data?.length === 0 ?  "" :
                        <div className=" search_sec  position-fixed px-3 " >
                                    <div className=" position-relative search_inner ">
                                        <input type="text" className="form-control rounded-pill " 
                                        name="search"
                                        value={searchData} 
                                        onChange={ (e) => setSearchData(e.target.value) }
                                        placeholder="Search by Title, Name"
                                        id="search_btn"
                                        style={{paddingLeft:"37px", paddingRight:"30px"}}
                                        />
                                        <span className="position-absolute top-0 start-0 ms-2 fs-4  d-flex  "
                                         style={{color: "grey", marginTop:"8px"}} >
                                            <BiSearchAlt2/> 
                                        </span>
                                        <span 
                                        className={`position-absolute  d-flex mt-2 top-0 end-0 me-2 fs-4 ${searchData.length === 0? 'd-none' : ""} `} 
                                        style={{color: "grey", cursor:"pointer"}} onClick={()=> setSearchData("")}
                                         >
                                            <RxCross2/> 
                                        </span>
                                    </div>

                                    <div className="btn-group ms-1" style={{width: "30px"}}>
                                        <button className="btn bg-light rounded fs-4 d-flex align-items-center border-0 btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <BiFilter/>
                                        </button>
                                        <ul className="dropdown-menu px-3">
                                            <li className={`dropdown-item ${sortData === "all"? 'text-info' : ""} `} onClick={() => {sortSearchData("all")}} > All</li>
                                            <li className={`dropdown-item ${sortData === "active"? 'text-info' : ""} `} onClick={() => sortSearchData("active")} > Active </li>
                                            <li className={`dropdown-item ${sortData === "deactive"? 'text-info' : ""} `} onClick={() => sortSearchData("deactive")}  > Deactive </li>

                                        </ul>
                                    </div>
                                    
                                <button  className="btn bg-info ms-3 bg-light rounded fs-3 d-flex align-items-center border-0 btn-sm"
                                    onClick={dataFetch}
                                 style={{width: "35px"}}> <TbReload/> </button>

                                <Link  className="btn bg-info ms-1 bg-light rounded fs-3 d-flex align-items-center border-0 btn-sm"
                                    to="/friends-ledgers-shared"
                                 style={{width: "35px"}}> <HiUsers/> </Link>
                        </div>
                    }

                    <div className=" d-flex cards_display flex-wrap ps-lg-4 " style={{paddingTop: "55px", paddingBottom:"60px"}} >
                    {/* <LoadingCards />  */}
                        {
                            ledgersData?.loading ? 
                                <LoadingName />
                            // <div className=" d-flex align-items-center justify-content-center" style={{height:"90vh", width:"100vw"}} >
                            //     <p className="text-center text-secondary">Loading...</p> 
                            // </div> 
                             :
                             ledgers?.length === 0 ?  
                                <div className=" w-100  bg-light d-flex justify-content-center text-secondary align-items-center" 
                                style={{height:"50vh", border:"1px dashed #8080807d", cursor: "pointer"}}
                                onClick={handleShow}
                                > 
                                Click here to create ledger. </div> 
                            :
                            ledgers?.filter((ele)=>{  

                                if(searchData.length === 0){
                                    return ele
                                }else{ 
                                    return ele.title.toLowerCase().includes(searchData.toLowerCase()) || ele.person_name.toLowerCase().includes(searchData.toLowerCase()) || ele.UName.toLowerCase().includes(searchData.toLowerCase()) 
                                }

                            })
                            ?.filter((ele)=>{
                                if(sortData === "all"){
                                    return ele
                                }else if(sortData === "active"){
                                    return ele.status === true
                                }else if(sortData === "deactive"){
                                    return ele.status === false
                                }
                            })
                            ?.map(ele =>{
                                
                                let res = Themes.find((item) => {
                                    return item.ID === ele.theme;
                                });

                                return(
                                    <div key={ele._id}>
                                    <motion.div
                                     animate={{ x: 0 }}
                                    >
                                        <Suspense fallback={<LoadingName />} >
                                            <LedgersCards EditLedgerFun={EditLedger} theme={res} id={ele._id} url={`/friends-ledger-details/${ele?._id}`}
                                            data={ele} authData={userData?.user} usersData={allUsers} />    
                                        </Suspense>
                                    </motion.div>
                                    </div>
                                )
                            })
                        }
    
                    </div>
                   
                </div>
                
    </div>
  )
}

export default FriendsLedgerDashboard



